import React, {useState} from 'react';
import PropTypes, {string, bool} from "prop-types";
import _JSXStyle from "styled-jsx/style";
import '../../styles/global.css'
import Header from "../Headerv2/index";
import Footer from "../Footer";
import PreFooter from "../Footer/PreFooter";
import 'react-toastify/dist/ReactToastify.css';
import AppContext from "../app-context";
import { ToastContainer } from 'react-toastify';

if (typeof global !== "undefined") {
    Object.assign(global, { _JSXStyle });
}

const Layout = (props) => {
    const {
        children,
        className,
        customFoot,
        prefootImg
    } = props;

    const [navVisible, setNavVisible] = useState(false);
    const [subItemsVisible, setSubItemsVisible] = useState(false);
    const toggleNavVisible = () => {
        if (navVisible) {
            setSubItemsVisible(false);
            setNavVisible(false);
            return;
        }

        setNavVisible(true);
    };
    const toggleSubItemsVisible = () => setSubItemsVisible(!subItemsVisible);

    const context = {
        navVisible,
        setNavVisible,
        toggleNavVisible,
        subItemsVisible,
        setSubItemsVisible,
        toggleSubItemsVisible,
    };


    return (
        <div className={className || "relative"} style={{ padding: 0, margin: 0}}>
            <AppContext.Provider value={context}>
                <Header
                    className={className}
                    transparentHeader={false}
                    hideBanner={true}
                    phoneNumber={null}
                    phoneText={null}
                    // noTopOffset={isDemoUrl ? "0" : noTopOffset}
                />
            </AppContext.Provider>
            <ToastContainer />
            <main className='p-0 m-0 md:mt-0 mt-[100px] z-0'>
                {children}
            </main>
            {!customFoot && (
                <PreFooter
                    title='Join team SpotOn'
                    subtext="At Spoton we’re always open to cooperating with talented developers. As an experienced team of senior programmers, we’re currently offering positions also to mid & junior developers and interns."
                    mainImg={prefootImg}
                />
            )}
            <Footer className={customFoot ? 'pt-[50px]' : ''} preText='Have an idea?' />
        </div>
    );
};

Layout.propTypes = {
    className: string,
    prefootImg: string,
    customFoot: bool,
}
Layout.defaultProps = {
    className: '',
    prefootImg: 'home-prefooter.png',
    customFoot: false,
}

export default Layout;


