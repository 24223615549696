import React from "react";
import { colors, viewports } from "../../style-vars";

const HeaderStyles = () => (
  <style jsx global>{`
    .header.transparent {
      background: transparent;
      box-shadow: none;
    }
    .header.nav-visible {
      background: ${colors.white};
    }
    .header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background: ${colors.white};
      height: 72px;
      width: 100%;
      padding: 0 20px;
      box-shadow: 0px 3.35045px 17.869px rgba(24, 36, 65, 0.0417275),
        0px 1.87823px 10.0172px rgba(24, 36, 65, 0.035);
      transition: 0.1s ease-in-out;
    }
  `}</style>
);

export default HeaderStyles;
