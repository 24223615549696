import React from "react";
import { colors, viewports } from "../../style-vars";

const MobileNavStyles = () => (
  <style jsx global>{`
    .mobile-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 100%;
      max-width: calc(100vw - 40px);
      margin-bottom: 60px;
    }

    .mobile-nav__burger button {
      margin: 0;
    }

    .mobile-nav__items-wrapper {
      position: fixed;
      right: 0;
      top: 70px;
      padding: 24px 0 80px;
      min-width: 375px;
      width: auto;
      background: ${colors.white};
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    body.demo-page .mobile-nav__items-wrapper {
      top: 70px;
    }

    .mobile-nav__ctas {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 50px;
    }
    .mobile-nav__ctas--login {
      background: none;
      outline: none;
      border: none;
      font-size: 18px;
      color: ${colors.primary};
    }
    .mobile-nav__ctas--login:hover {
      cursor: pointer;
    }
    .mobile-nav__ctas .cta-primary {
      margin: 0 !important;
    }

    .mobile-nav__items {
      display: flex;
      flex-direction: column;
    }

    button.mobile-nav__item {
      background: none;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
    }
    button.mobile-nav__item:hover {
      cursor: pointer;
    }
    a.mobile-nav__item {
      line-height: 38px;
      margin: 0;
    }
    .mobile-nav__item {
      z-index: 10;
      height: 100%;
      font-weight: bold;
      font-size: 18px;
      color: #090d12;
      margin: 0 5px;
      padding: 15px 24px;
      transition: 0.5s;
    }
    .mobile-nav__items-list {
      padding: 0 24px;
    }
    .mobile-nav__items-list.active {
      border-left: 3px solid ${colors.primary};
    }

    .mobile-nav__item--arrow {
      margin-left: 10px;
      transform: rotate(90deg);
      transition: 0.3s ease-in-out;
    }
    .mobile-nav__item.active .mobile-nav__item--arrow {
      transform: rotate(-90deg);
    }

    .mobile-nav__items-list .main-nav-item {
      padding: 16px 0;
      width: 100%;
    }

    .mobile-nav__sub-items-wrapper {
      position: fixed;
      top: 71px;
      right: 0;
      min-width: 375px;
      width: auto;
      background: ${colors.white};
      overflow-y: scroll;
    }

    .mobile-nav__sub-items-title {
      width: 100%;
      margin-top: 20px;
      font-weight: normal;
      font-size: 16px;
      color: #090d12;
      background: none;
      outline: none;
      padding: 26px 12px;
      border: none;
      border-bottom: 3px solid ${colors.primary};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mobile-nav__sub-items-title svg {
      transform: rotate(180deg);
      flex: 0 1 10%;
    }
    .mobile-nav__sub-items-title .title {
      flex: 0 1 80%;
    }
    .mobile-nav__sub-items-title .placeholder {
      flex: 0 1 10%;
    }

    .mobile-nav__sub-items-wrapper .main-nav-subitem__info--label {
      font-weight: bold;
    }

    .mobile-nav__items-wrapper,
    .mobile-nav__sub-items-wrapper {
      height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 100%;
      padding-bottom: 80px;
      width: 80%;
    }

    @media ${viewports.xsOnly} {
      .mobile-nav__items-wrapper,
      .mobile-nav__sub-items-wrapper {
        width: 100vw;
        //height: 100vh;
      }
      .mobile-nav__items {
      }
    }

    @media ${viewports.lgOrBigger} {
      .mobile-nav {
        display: none;
      }
    }
  `}</style>
);

export default MobileNavStyles;
