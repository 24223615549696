const screenXs = `480px`;
const screenSm = `576px`;
const screenMd = `768px`;
const screenLg = `992px`;
const screenXl = `1200px`;
const screenXxl = `1600px`;

const screenXsMin = screenXs;
const screenSmMin = screenSm;
const screenMdMin = screenMd;
const screenLgMin = screenLg;
const screenXlMin = screenXl;
const screenXxlMin = screenXxl;

const screenXsMax = `575px`;
const screenSmMax = `767px`;
const screenMdMax = `991px`;
const screenLgMax = `1199px`;
const screenXlMax = `1599px`;

export const viewports = {
    xsOnly: `only screen and (max-width: ${screenXsMax})`,
    allExceptXs: `only screen and (min-width: ${screenXsMax})`,
    smOnly: `only screen and (max-width: ${screenSmMax}) and (min-width: ${screenSm})`,
    smOrSmaller: `only screen and (max-width: ${screenSmMax})`,
    smOrBigger: `only screen and (min-width: ${screenSmMin})`,
    mdOnly: `only screen and (max-width: ${screenMdMax}) and (min-width: ${screenMd})`,
    mdOrSmaller: `only screen and (max-width: ${screenMdMax})`,
    mdOrBigger: `only screen and (min-width: ${screenMdMin})`,
    lgOnly: `only screen and (max-width: ${screenLgMax}) and (min-width: ${screenLg})`,
    lgOrSmaller: `only screen and (max-width: ${screenLgMax})`,
    lgOrBigger: `only screen and (min-width: ${screenLgMin})`,
    xlOnly: `only screen and (max-width: ${screenXlMax}) and (min-width: ${screenXl})`,
    xlOrSmaller: `only screen and (max-width: ${screenXlMax})`,
    xlOrBigger: `only screen and (min-width: ${screenXlMin})`,
    maxContentWidthOrBigger: `only screen and (min-width: 1440px)`,
};

export const colors = {
    primary: '#1769FF',
    info: '#1769FF',
    success: '#00AB4F',
    processing: '#1769FF',
    error: '#F73E3C',
    highlight: '#F73E3C',
    warning: '#FFC043',
    normal: '#d9d9d9',
    white: '#fff',
    black: '#000',
    textGray: '#C4C4C4',
    textDarkGray: '#2C2C2C',
    paragraph: '#748093',
    black200: '#445469',
    primary1: '#74A5FF',
    primaryBackground: '#07204D',
};

export const fontFamily = `'Poppins', sans-serif`;

// @media only screen and (max-width: 991px) {
// @media ${mdOrSmaller} {

// @media only screen and (max-width: 767px) {
// @media ${smOrSmaller} {

// @media only screen and (max-width: 575px) {
// @media ${xsOnly} {

// @media only screen and (max-width: 1199px) and (min-width: 992px) {
// @media ${lgOnly} {

// @media only screen and (max-width: 991px) and (min-width: 768px) {
// @media ${mdOnly} {

// @media only screen and (min-width: 992px) {
// @media ${lgOrBigger} {

// @media only screen and (max-width: 1199px) {
// @media ${lgOrSmaller} {

// @media only screen and (min-width: 768px) {
// @media ${mdOrBigger} {

// @media only screen and (min-width: 1200px) {
// @media ${xlOrBigger} {

// @media only screen and (max-width: 1599px) and (min-width: 1200px) {
// @media ${xlOnly} {
