const navData = [
  {
    label: "Home",
    key: "home",
    path: "/",
  },
  {
    label: "What we do",
    key: "what-we-do",
    path: "/what-we-do",
  },
  {
    label: "Careers",
    key: "careers",
    path: "/careers",
  },
  {
    label: "Contact Us",
    key: "contact-us",
    path: "/contact-us",
  },
];

export default navData;
