// IMPORTANT!!!
// Nav components will look up these keys from the keys set in the
// nav-data.js file

import React from "react";

import Others from "../../images/svg/nav-icons/others.svg";
import Reward from "../../images/svg/nav-icons/reward.svg";
import Sales from "../../images/svg/nav-icons/sales.svg";
import Team from "../../images/svg/nav-icons/team.svg";
import Knowledge from "../../images/svg/nav-icons/knowledge.svg";
import Why from "../../images/svg/nav-icons/why.svg";
import Restaurants from "../../images/svg/nav-icons/restaurants.svg";
import FineDining from "../../images/svg/nav-icons/restaurants-fine-dining.svg";
import CasualDining from "../../images/svg/nav-icons/restaurants-casual-dining.svg";
import QSR from "../../images/svg/nav-icons/restaurants-qsr.svg";
import FoodStore from "../../images/svg/nav-icons/restaurants-food-store.svg";
import FoodTruck from "../../images/svg/nav-icons/restaurants-food-truck.svg";
import BarsAndNightclubs from "../../images/svg/nav-icons/restaurants-bars-nightclubs.svg";
import Automotive from "../../images/svg/nav-icons/automotive.svg";
import OilChangeShop from "../../images/svg/nav-icons/automotive-oil-change-shop.svg";
import CarLots from "../../images/svg/nav-icons/automotive-car-lots.svg";
import AutoBody from "../../images/svg/nav-icons/automotive-auto-body.svg";
import AutoParts from "../../images/svg/nav-icons/automotive-auto-parts.svg";
import TintShop from "../../images/svg/nav-icons/automotive-tint-shop.svg";
import AutoSales from "../../images/svg/nav-icons/automotive-auto-sales.svg";
import AutoRepair from "../../images/svg/nav-icons/automotive-auto-repair.svg";
import TireShop from "../../images/svg/nav-icons/automotive-tire-shop.svg";
import VehicleWraps from "../../images/svg/nav-icons/automotive-vehicle-wraps.svg";
import TowTrucks from "../../images/svg/nav-icons/automotive-tow-trucks.svg";
import HealthAndBeauty from "../../images/svg/nav-icons/health-beauty.svg";
import NailSalon from "../../images/svg/nav-icons/health-beauty-nail-salon.svg";
import Barber from "../../images/svg/nav-icons/health-beauty-barber.svg";
import Dentist from "../../images/svg/nav-icons/health-beauty-dentist.svg";
import HairSalon from "../../images/svg/nav-icons/health-beauty-hair-salon.svg";
import Chiropractor from "../../images/svg/nav-icons/health-beauty-chiropractor.svg";
import Vet from "../../images/svg/nav-icons/health-beauty-vet.svg";
import TanningSalon from "../../images/svg/nav-icons/health-beauty-tanning-salon.svg";
import Acupuncture from "../../images/svg/nav-icons/health-beauty-acupuncture.svg";
import PetBoarding from "../../images/svg/nav-icons/health-beauty-pet-boarding.svg";
import ProfessionalServices from "../../images/svg/nav-icons/prof-services.svg";
import Accountant from "../../images/svg/nav-icons/prof-services-accountant.svg";
import Lawyer from "../../images/svg/nav-icons/prof-services-lawyer.svg";
import Appraiser from "../../images/svg/nav-icons/prof-services-appraiser.svg";
import Plumber from "../../images/svg/nav-icons/prof-services-plumber.svg";
import Handyman from "../../images/svg/nav-icons/prof-services-handyman.svg";
import Architect from "../../images/svg/nav-icons/prof-services-architect.svg";
import Locksmith from "../../images/svg/nav-icons/prof-services-locksmith.svg";
import Landscaper from "../../images/svg/nav-icons/prof-services-landscaper.svg";
import Retail from "../../images/svg/nav-icons/retail.svg";
import HomeDecor from "../../images/svg/nav-icons/retail-home-decor.svg";
import SportingGoods from "../../images/svg/nav-icons/retail-sporting-goods.svg";
import ClothingAndApparel from "../../images/svg/nav-icons/retail-clothing.svg";
import Appointments from "../../images/svg/nav-icons/products-appointments.svg";
import Payments from "../../images/svg/nav-icons/products-payments.svg";
import ECommerce from "../../images/svg/nav-icons/products-e-commerce.svg";
import OnlineOrdering from "../../images/svg/nav-icons/products-online-ordering.svg";
import Reporting from "../../images/svg/nav-icons/products-reporting.svg";
import TerminalPos from "../../images/svg/nav-icons/products-terminal-pos.svg";
import Reserve from "../../images/svg/nav-icons/products-reserve.svg";
import VirtualTerminal from "../../images/svg/nav-icons/products-virtual-terminal.svg";
import Marketing from "../../images/svg/nav-icons/products-marketing.svg";
import Capital from "../../images/svg/nav-icons/products-capital.svg";
import ReviewManagement from "../../images/svg/nav-icons/products-review-management.svg";
import Loyalty from "../../images/svg/nav-icons/products-loyalty.svg";
import DeliveryLogistics from "../../images/svg/nav-icons/products-delivery-logistics.svg";
import Payroll from "../../images/svg/nav-icons/products-payroll.svg";
import Delivery from "../../images/svg/nav-icons/products-delivery.svg";
import Website from "../../images/svg/nav-icons/products-website.svg";
import Websites from "../../images/svg/nav-icons/products-websites.svg";
import Pos from "../../images/svg/nav-icons/products-pos.svg";
import Hardware from "../../images/svg/nav-icons/products-hardware.svg";
import Report from "../../images/svg/nav-icons/products-report.svg";
import Revenue from "../../images/svg/nav-icons/products-revenue.svg";
import GiftCard from "../../images/svg/nav-icons/products-gift-card.svg";
import Discount from "../../images/svg/nav-icons/discount.svg";
import KitchenDisplays from "../../images/svg/nav-icons/kitchen-displays.svg";
import OnlineReservation from "../../images/svg/nav-icons/online-reservation.svg";
import Enterprise from "../../images/svg/nav-icons/enterprise.svg";
import Kiosk from "../../images/svg/nav-icons/products-kiosk.svg";
import Handhelds from "../../images/svg/nav-icons/products-handhelds.svg";
import SportsAndEntertainment from "../../images/svg/nav-icons/sports-entertainment.svg";
import Campuses from "../../images/svg/nav-icons/campuses.svg";
import Attractions from "../../images/svg/nav-icons/attractions.svg";
import FoodServices from "../../images/svg/nav-icons/food-services.svg";

const keyValueArray = [
  ["others", <Others />],
  ["reward", <Reward />],
  ["sales", <Sales />],
  ["team", <Team />],
  ["knowledge", <Knowledge />],
  ["why", <Why />],
  ["restaurants", <Restaurants />],
  ["restaurants-overview", <Others />],
  ["restaurants-fine-dining", <FineDining />],
  ["restaurants-casual-dining", <CasualDining />],
  ["restaurants-qsr", <QSR />],
  ["restaurants-food-store", <FoodStore />],
  ["restaurants-food-truck", <FoodTruck />],
  ["restaurants-bars-and-nightclubs", <BarsAndNightclubs />],
  ["restaurants-advisory-council", <Why />],
  ["automotive", <Automotive />],
  ["automotive-overview", <Others />],
  ["automotive-oil-change-shop", <OilChangeShop />],
  ["automotive-car-lots", <CarLots />],
  ["automotive-auto-body", <AutoBody />],
  ["automotive-auto-parts", <AutoParts />],
  ["automotive-tint-shop", <TintShop />],
  ["automotive-auto-sales", <AutoSales />],
  ["automotive-auto-repair", <AutoRepair />],
  ["automotive-tire-shop", <TireShop />],
  ["automotive-vehicle-wraps", <VehicleWraps />],
  ["automotive-tow-trucks", <TowTrucks />],
  ["health-and-beauty", <HealthAndBeauty />],
  ["health-and-beauty-overview", <Others />],
  ["health-and-beauty-nail-salon", <NailSalon />],
  ["health-and-beauty-barber", <Barber />],
  ["health-and-beauty-dentist", <Dentist />],
  ["health-and-beauty-hair-salon", <HairSalon />],
  ["health-and-beauty-chiropractor", <Chiropractor />],
  ["health-and-beauty-veterinarian", <Vet />],
  ["health-and-beauty-tanning-salon", <TanningSalon />],
  ["health-and-beauty-acupuncture", <Acupuncture />],
  ["health-and-beauty-pet-boarding", <PetBoarding />],
  ["professional-services", <ProfessionalServices />],
  ["professional-services-overview", <Others />],
  ["professional-services-accountant", <Accountant />],
  ["professional-services-lawyer", <Lawyer />],
  ["professional-services-appraiser", <Appraiser />],
  ["professional-services-plumber", <Plumber />],
  ["professional-services-handyman", <Handyman />],
  ["professional-services-architect", <Architect />],
  ["professional-services-locksmith", <Locksmith />],
  ["professional-services-landscaper", <Landscaper />],
  ["retail", <Retail />],
  ["retail-overview", <Others />],
  ["retail-home-decor", <HomeDecor />],
  ["retail-sporting-goods", <SportingGoods />],
  ["retail-clothing-and-apparel", <ClothingAndApparel />],
  ["products-payments-overview", <Payments />],
  ["products-restaurant", <Restaurants />],
  ["products-retail", <Retail />],
  ["products-food-truck", <FoodTruck />],
  ["products-appointments", <Appointments />],
  ["products-payments", <Payments />],
  ["products-e-commerce", <ECommerce />],
  ["products-customers-e-commerce", <ECommerce />],
  ["products-online-ordering", <OnlineOrdering />],
  ["products-reporting", <Reporting />],
  ["products-terminal-pos", <TerminalPos />],
  ["products-reserve", <Reserve />],
  ["products-customers-reserve", <Reserve />],
  ["products-virtual-terminal", <VirtualTerminal />],
  ["products-marketing", <Marketing />],
  ["products-capital", <Capital />],
  ["products-review-management", <ReviewManagement />],
  ["products-loyalty-overview", <Loyalty />],
  ["products-loyalty", <Loyalty />],
  ["products-delivery-logistics", <DeliveryLogistics />],
  ["products-payroll", <Payroll />],
  ["products-delivery", <Delivery />],
  ["products-order-delivery", <Delivery />],
  ["labor-management", <VirtualTerminal />],
  ["products-customers-order-delivery", <Delivery />],
  ["products-website", <Website />],
  ["products-websites", <Websites />],
  ["products-pos", <Pos />],
  ["products-hardware", <Hardware />],
  ["products-report", <Report />],
  ["products-revenue", <Revenue />],
  ["products-gift-card", <GiftCard />],
  ["discount", <Discount />],
  ["kitchen-displays", <KitchenDisplays />],
  ["online-reservation", <OnlineReservation />],
  ["products-experiences-at-scale", <Enterprise />],
  ["bt-enterprise", <Enterprise />],
  ["products-scale-kiosks", <Kiosk />],
  ["products-scale-pos-terminals", <TerminalPos />],
  ["products-scale-online-ordering", <VirtualTerminal />],
  ["products-scale-handhelds", <Handhelds />],
  ["products-scale-payment-devices", <Payments />],
  ["products-scale-back-of-house-management", <Revenue />],
  ["products-scale-kitchen-displays", <KitchenDisplays />],
  ["bt-enterprise-overview", <DeliveryLogistics />],
  ["bt-enterprise-sports", <SportsAndEntertainment />],
  ["bt-enterprise-campuses", <Campuses />],
  ["bt-enterprise-facilities", <ProfessionalServices />],
  ["bt-enterprise-attractions", <Attractions />],
  ["bt-enterprise-food-services", <FoodServices />],
  ["bt-enterprise-retail", <Retail />],
];

const icons = new Map(keyValueArray);

export default icons;
