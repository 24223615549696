import React from 'react';
import {string, bool, oneOf} from "prop-types";
import Image from "../ImageQueries/HeroImages";
import Title from "../Title";
import {Link} from "gatsby";
import chevron from "../../images/svg/chevron_left.svg";

const PreFooter = (props) => {
    const {className, title, subtext, mainImg} = props
    return (
        <div className='w-full relative z-[9] xl:mx-auto'>
            <Image imageName={mainImg} className='min-h-[350px] md:min-h-[700px] xl:min-h-[350px] max-h-[700px]' />
            <div className='relative max-w-[1440px] 2xl:mx-auto'>
                <div className='bg-black h-[400px] absolute z-90 lg:right-[160px] lg:left-auto md:left-[20%] md:translate-y-[-50%] lg:top-[-165px] top-[-120px] translate-x-[0] md:-bottom-16 -bottom-96 max-w-[448px]  rounded-lg lg:mx-0 mx-[16px]'>
                    <div className='px-8 pt-4 pb-0'>
                        <Title level={1} withBlueSymbol symbol='.' className='text-white text-[42px] leading-[140%]' style={{ marginBottom: 16}}>{title}</Title>
                        <p className='font-sans text-black-800 leading-6 md:mb-32 mb-2 text-[16px] leading-[160%] mt-0'>{subtext}</p>
                    </div>
                    <Link to='/careers' className='no-underline '><p className='w-full bg-primary px-4 py-6 mt-16 text-center text-white font-sans no-underline rounded-b-lg font-bold mb-0 box-border leading-[120%]	text-[18px]'>Learn more <img className="ml-2.5 translate-y-[2px]	" alt="chevron icon" src={chevron} /></p></Link>
                </div>
            </div>
        </div>
    );
};
PreFooter.propTypes = {
    className: string,
    title: string,
    subtext: string,
    mainImg: string,
};

PreFooter.defaultProps = {
    className: '',
    title: 'Join team SpotOn',
    subtext: '',
    mainImg: '',
};

export default PreFooter;
