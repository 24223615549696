import React from "react";
import { string, bool } from "prop-types";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { colors, viewports } from "../../style-vars";
import icons from "./icons-map";

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
};

const LinkWithRef = React.forwardRef((props, ref) => (
  <Link className="main-nav-subitem" to={props.path} ref={ref}>
    <div className="main-nav-subitem__info-wrapper">
      <div className="main-nav-subitem__icon">{icons.get(props.keyProp)}</div>
      <div className="main-nav-subitem__info">
        <span className="main-nav-subitem__info--label">{props.label}</span>
        <span className="main-nav-subitem__info--description">
          {props.description}
        </span>
      </div>
    </div>
  </Link>
));

const MotionLink = motion(LinkWithRef, {
  forwardMotionProps: true,
});

const MainNavSubItem = (props) => {
  return (
    <>
      <MotionLink
        {...props}
        variants={variants}
        initial={isMobile ? "visible" : "hidden"}
        animate={props.animate || isMobile ? "visible" : "hidden"}
      />

      <style jsx global>{`
        .main-nav-subitem {
          background: none;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          margin: 4px 0;
          transition: 0.2s ease-in-out;
        }
        .main-nav-subitem:hover,
        .main-nav-subitem.active {
          cursor: pointer;
          background: #e8f0ff;
          border-radius: 6px;
        }
        .main-nav-subitem:hover .main-nav-subitem__info--label,
        .main-nav-subitem.active .main-nav-subitem__info--label {
          color: ${colors.primary};
        }
        :global(.main-nav-subitem:hover svg rect),
        :global(.main-nav-subitem.active svg rect) {
          transition: 0.2s ease-in-out;
          fill: white;
        }

        .main-nav-subitem__icon {
          width: 24px;
          height: 24px;
          transition: 0.2s ease-in-out;
        }
        .main-nav-subitem__icon svg {
          border-radius: 6px;
          width: 24px;
          height: 24px;
        }
        .main-nav-subitem:hover .main-nav-subitem__icon {
          //width: 48px;
          //height: 48px;
          border-radius: 6px;
        }
        //.main-nav-subitem:hover .main-nav-subitem__icon svg {
        //  width: 48px;
        //  height: 48px;
        //}

        // <--- Icons colors --->
        .main-nav-subitem:hover .main-nav-subitem__icon svg rect,
        .main-nav-subitem.active .main-nav-subitem__icon svg rect {
          transition: 0.2s ease-in-out;
          fill: ${colors.primary};
        }
        .main-nav-subitem:hover svg path,
        .main-nav-subitem:hover svg circle,
        .main-nav-subitem:hover svg rect,
        .main-nav-subitem:hover svg line,
        .main-nav-subitem.active svg path,
        .main-nav-subitem.active svg circle,
        .main-nav-subitem.active svg rect,
        .main-nav-subitem.active svg line {
          stroke: ${colors.white};
        }
        .main-nav-subitem:hover .main-nav-subitem__arrow rect.background,
        .main-nav-subitem.active .main-nav-subitem__arrow rect.background {
          transition: 0.2s ease-in-out;
          fill: ${colors.white};
        }
        // <--- End of Icons colors --->

        .main-nav-subitem__info-wrapper {
          display: flex;
          align-items: center;
        }

        .main-nav-subitem__info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .main-nav-subitem__info--label {
          font-weight: 400;
          font-size: 16px;
          color: #090d12;
        }

        .main-nav-subitem__info--description {
          font-weight: normal;
          font-size: 14px;
          color: #748093;
        }

        @media ${viewports.lgOrBigger} {
          .main-nav-subitem {
            width: 450px;
          }

          .main-nav-subitem__info {
            margin-left: 24px;
          }

          .main-nav-subitem__info--label {
            //font-weight: normal;
            font-size: 18px;
          }
        }

        @media ${viewports.lgOrBigger} {
          .main-nav-subitem {
            flex: 0 1 45%;
          }
        }
      `}</style>
    </>
  );
};

MainNavSubItem.propTypes = {
  keyProp: string.isRequired,
  label: string.isRequired,
  path: string,
  description: string,
  animate: bool,
};

MainNavSubItem.defaultProps = {
  description: "",
  path: null,
  animate: false,
};

export default MainNavSubItem;
