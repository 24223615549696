import React, { useContext, useState, useRef, useEffect } from "react";
import { bool, string } from "prop-types";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import navData from "./nav-data";
import LogoBlack from "../../images/svg/SpotOnLogo.svg";
import LogoWhite from "../../images/svg/SpotOnLogoWhite.svg";
import AppContext from "../app-context";
import HeaderContext from "./header-context";
import ArrowIcon from "../../images/svg/nav-icons/arrow.svg";
import DesktopNavStyles from "./desktop-nav-styles";
import useWindowSize from "../hooks/use-window-size";

const DesktopNav = ({
  transparentHeader,
}) => {
  const [loginMenuOpened, setLoginMenuOpened] = useState(false);
  const [addScrollOnNav, setAddScrollOnNav] = useState(0);
  const [delay, setDelay] = useState(null);
  const navRef = useRef(null);
  const { height } = useWindowSize();

  const { navVisible, setNavVisible, setSubItemsVisible } =
    useContext(AppContext);

  const {
    activeKey,
    setActiveKey,
    setActiveMainNavItemKey,
    setMainNavItems,
    setMainNavSubItems,
    pagePath,
  } = useContext(HeaderContext);

  useEffect(() => {
    setAddScrollOnNav(navRef?.current?.clientHeight > height - 130);
  }, [activeKey, height]);

  const onButtonClick = (key, items, isHover = false) => {
    if (!isHover && key === activeKey && navVisible) {
      return setNavVisible(false);
    }

    if (isHover && key !== activeKey) {
      setNavVisible(false);
      setSubItemsVisible(false);
    }

    setTimeout(() => {
      setActiveKey(key);
      if (items.length) {
        setMainNavItems(items);
        setMainNavSubItems(items[0]?.subItems ?? []);
      }
      setActiveMainNavItemKey(items[0]?.key ?? "");
      setNavVisible(true);
      return setSubItemsVisible(key === "business-types" || key === "products");
    }, 100);
  };

  const onLinkClick = (key) => {
    setActiveKey(key);
    setActiveMainNavItemKey("");
    setMainNavItems([]);
    setMainNavSubItems([]);
    setNavVisible(false);
    setSubItemsVisible(false);
  };

  const onMouseEnter = (key, items, isHover) => {
    if (!isMobile) {
      const t = setTimeout(() => {
        onButtonClick(key, items, isHover);
      }, 500);
      setDelay(t);
    }
  };

  const onMouseLeave = () => {
    clearTimeout(delay);
  };

  return (
    <div className="desktop-nav">
      <div className="desktop-nav__logo">
        <Link to="/" onClick={() => onLinkClick("home")} >
          <img
            className="header__logo"
            src={transparentHeader && !navVisible ? LogoWhite : LogoBlack}
            alt="logo"
          />
        </Link>
      </div>

      {/* Main navigation at the top */}
      <nav className="desktop-nav__items">

      </nav>

      <div className="desktop-nav__ctas" onMouseEnter={() => setActiveKey("")}>
        <div className="desktop-nav__ctas--login-wrapper mr-6">
          {navData.map(({ label, key, path, items, externalURL }) => {
            const isActive = activeKey === key && navVisible;
            const className = `desktop-nav__item no-underline justify-self-center ${isActive ? "active" : ""}`;
            if (path) {
              return externalURL ? (
                  <a
                      key={key}
                      className={className}
                      href={path}
                      onClick={() => onLinkClick(key)}
                      onMouseEnter={() => onLinkClick(key)}
                  >
                    {label}
                  </a>
              ) : (
                  <Link
                      key={key}
                      className={className}
                      to={path}
                      activeClassName='active-nav'
                      onClick={() => onLinkClick(key)}
                      onMouseEnter={() => onLinkClick(key)}
                  >
                    {label}
                  </Link>
              );
            }
          })}
        </div>
      </div>

      <DesktopNavStyles
        addScrollOnNav={addScrollOnNav}
        windowHeight={height ?? 0}
      />
    </div>
  );
};

DesktopNav.propTypes = {
  transparentHeader: bool,
  isDemoURL: bool,
  phoneNumber: string,
  phoneText: string,
};

DesktopNav.defaultProps = {
  transparentHeader: false,
  isDemoURL: false,
  phoneNumber: null,
  phoneText: null,
};

export default DesktopNav;
