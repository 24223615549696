import React, { useContext, useEffect, useState } from "react";
import { bool, string } from "prop-types";
import { motion } from "framer-motion";
import Burger from "react-css-burger";
import { Link } from "gatsby";
import AppContext from "../app-context";
import HeaderContext from "./header-context";
import LogoWhite from "../../images/svg/SpotOnLogoWhite.svg";
import LogoBlack from "../../images/svg/SpotOnLogo.svg";
import MobileNavStyles from "./mobile-nav-styles";
import navData from "./nav-data";
import ArrowIcon from "../../images/svg/nav-icons/arrow.svg";
import MainNavItem from "./main-nav-item";
import MainNavSubItem from "./main-nav-sub-item";

const navVariants = {
  hidden: { opacity: 0, x: "100vw" },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
    },
  },
};

const listItemsVariants = {
  hidden: { opacity: 0, height: 0, x: "100vw" },
  visible: {
    opacity: 1,
    height: "auto",
    x: 0,
    transition: {
      duration: 0.4,
    },
  },
};

const loginItems = [
  {
    label: "as Merchant",
    key: "1",
    path: "https://login.spoton.com/home/",
  },
  {
    label: "as Partner",
    key: "2",
    path: "https://thespot.spoton.com/",
  },
];

const MobileNav = ({ transparentHeader, phoneText, phoneNumber }) => {
  const [burgerMenu, setBurgerMenu] = useState(null);
  const [loginMenuOpened, setLoginMenuOpened] = useState(false);
  const [subItemsToShow, setSubItemsToShow] = useState([]);

  const {
    navVisible,
    setNavVisible,
    toggleNavVisible,
    subItemsVisible,
    setSubItemsVisible,
    toggleSubItemsVisible,
  } = useContext(AppContext);

  const {
    activeKey,
    setActiveKey,
    activeMainNavItemKey,
    setActiveMainNavItemKey,
    mainNavItems,
    setMainNavItems,
    mainNavSubItems,
    setMainNavSubItems,
    pagePath,
    subItemsTitle,
    setSubItemsTitle,
  } = useContext(HeaderContext);

  const toggleNav = () => {
    if (navVisible) {
      setActiveKey("");
      setMainNavItems([]);
      setActiveMainNavItemKey("");
      setMainNavSubItems([]);
      // setSubItemsVisible(false);
      return setNavVisible(false);
    }

    return setNavVisible(true);
  };

  useEffect(() => {
    const burger = (
      <Burger
        active={navVisible}
        onClick={toggleNav}
        burger="spring"
        color={transparentHeader && !navVisible ? "#fff" : "#000"}
      />
    );

    setBurgerMenu(burger);
  }, [navVisible, transparentHeader]);

  useEffect(() => {
    setSubItemsToShow(loginMenuOpened ? loginItems : mainNavSubItems);
  }, [loginMenuOpened, mainNavSubItems]);

  const onLinkClick = (key) => {
    setActiveKey(key);
    setNavVisible(false);
    setSubItemsVisible(false);
  };

  const onButtonClick = (key, items) => {
    if (key === activeKey) {
      // return toggleNavVisible();
      return setActiveKey("");
    }

    setActiveKey(key);
    setMainNavItems(items);
    setActiveMainNavItemKey("");
    setMainNavSubItems([]);
    return setSubItemsVisible(false);
  };

  const onSubItemsTitleClick = () => {
    setSubItemsTitle("");
    setSubItemsVisible(false);
    setLoginMenuOpened(false);
  };

  const onLoginClick = () => {
    setLoginMenuOpened(true);
    setSubItemsTitle("Login");
  };

  return (
    <div className="mobile-nav">
      <div className="mobile-nav__logo">
        <Link to="/" onClick={() => onLinkClick("home")}>
          <img
            className="header__logo"
            src={transparentHeader && !navVisible ? LogoWhite : LogoBlack}
            alt="logo"
          />
        </Link>
      </div>

      <div className="mobile-nav__burger">{burgerMenu}</div>

      <motion.div
        className="mobile-nav__items-wrapper"
        variants={navVariants}
        initial="hidden"
        animate={navVisible ? "visible" : "hidden"}
      >
        <nav className="mobile-nav__items">
          {navData.map(({ label, key, path, items, externalURL }) => {
            const isActive = activeKey === key && navVisible;
            const className = `mobile-nav__item no-underline ${isActive ? "active" : ""}`;

            if (path) {
              return externalURL ? (
                <a
                  key={`${key}-mobile`}
                  className={className}
                  href={path}
                  onClick={() => onLinkClick(key)}
                >
                  {label}
                </a>
              ) : (
                <Link
                  key={`${key}-mobile`}
                  className={className}
                  to={path}
                  onClick={() => onLinkClick(key)}
                  activeClassName='active-nav'
                >
                  {label}
                </Link>
              );
            }
            return (
              <>
                <button
                  key={`${key}-mobile`}
                  type="button"
                  className={className}
                  onClick={() => onButtonClick(key, items)}
                >
                  {label}
                  <ArrowIcon className="mobile-nav__item--arrow" />
                </button>

                <motion.div
                  className={`mobile-nav__items-list ${
                    isActive ? "active" : ""
                  }`}
                  variants={listItemsVariants}
                  initial="hidden"
                  animate={isActive ? "visible" : "hidden"}
                >
                  {items.map((item) => (
                    <MainNavItem
                      key={`${item.key}-mobile`}
                      keyProp={item.key}
                      path={item.path}
                      label={item.label}
                      description={item.description}
                      subItems={item.subItems}
                    />
                  ))}
                </motion.div>
              </>
            );
          })}
        </nav>
      </motion.div>

      <MobileNavStyles />
    </div>
  );
};

MobileNav.propTypes = {
  transparentHeader: bool,
  phoneNumber: string,
  phoneText: string,
};

MobileNav.defaultProps = {
  transparentHeader: false,
  phoneNumber: null,
  phoneText: null,
};

export default MobileNav;
