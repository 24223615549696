import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import Title from '../Title';
import { Link } from 'gatsby';
import whiteLogo from '../../images/SpotOn-Logo.svg';
import fb from '../../images/svg/fb.svg';
import ig from '../../images/svg/instagram.svg';
import tw from '../../images/svg/twitter.svg';
import yt from '../../images/svg/youtube.svg';
import linkedin from '../../images/svg/linkedin.svg';
import chevron from '../../images/svg/chevron_left.svg';
const Footer = (props) => {
    const { className, preText } = props;
    return (
        <footer
            className={`${className} bg-black md:pt-24 pt-[350px] lg:px-0 lg:py-32 md:p-12 md:px-8 p-[16px] z-10`}
        >
            <div className="max-w-[1440px] lg:px-[160px] xl:mx-auto ">
                <div className="grid xl:grid-cols-[2fr,1fr] sm:grid-cols-1 gap-4 ">
                    <div>
                        <Title
                            level={1}
                            className="text-bold text-white font-black text-[42px] leading-[140%] lg:text-[72px] lg:leading-[110%]"
                            style={{ marginBottom: 0, paddingBottom: 0 }}
                        >
                            {preText}
                        </Title>
                        <Title
                            level={1}
                            className="text-white font-thin text-[42px] leading-[140%] lg:text-[72px] lg:leading-[110%]"
                            style={{ margin: 0, padding: 0 }}
                        >
                            Tell us about it
                        </Title>
                    </div>
                    <Link
                        to="/contact-us"
                        className="no-underline xl:self-end self-start xl:justify-self-end justify-self-start"
                    >
                        <p className="text-white font-sans p-4 no-underline	font-bold text-[16px] rounded-full border-white border-solid text-center block w-42 max-w-[175px]">
                            Say hello to us{' '}
                            <img
                                className="ml-2.5 translate-y-[2px]	"
                                alt="chevron icon"
                                src={chevron}
                            />
                        </p>
                    </Link>
                </div>
                <div className="md:mt-32 mt-8 flex items-start xl:items-center flex-col xl:flex-row gap-5">
                    <div className="xl:order-first order-last">
                        <img src={whiteLogo} alt="" className="w-32" />
                        <p className="font-sans text-white text-[12px] leading-[180%] fon max-w-[410px]">
                            © SpotOn Transact, Inc. 2020. All Rights Reserved.
                            SpotOn Transact, Inc. is a registered ISO/MSP of
                            Merrick Bank, South Jordan, UT.
                        </p>
                    </div>

                    <div
                        className={`
                            w-full flex flex-row justify-between md:justify-start xl:justify-end md:mb-10 xl:mb-0
                        `}
                    >
                        <Link
                            to="/what-we-do"
                            className="font-sans no-underline py-2 text-white hover:bg-gray-900 hover:text-primary md:mr-10 xl:mr-0 xl:ml-24"
                        >
                            <span className="leading-0">What we do</span>
                        </Link>
                        <Link
                            to="/careers"
                            className="font-sans no-underline py-2 text-white hover:bg-gray-900 hover:text-primary md:mr-10 xl:mr-0 xl:ml-24"
                        >
                            <span>Careers</span>
                        </Link>
                        <Link
                            to="/contact-us"
                            className="font-sans no-underline py-2 text-white hover:bg-gray-900 hover:text-primary md:mr-10 xl:mr-0 xl:ml-24"
                        >
                            <span>Contact</span>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};
Footer.propTypes = {
    className: string,
    preText: string,
};

Footer.defaultProps = {
    className: '',
    preText: 'Have an idea?',
};

export default Footer;
