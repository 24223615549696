import React, { useContext, useRef } from "react";
import { motion } from "framer-motion";
import MainNavItem from "./main-nav-item";
import AppContext from "../app-context";
import HeaderContext from "./header-context";
import MainNavSubItem from "./main-nav-sub-item";

const navVariants = {
  hidden: { visibility: "hidden", y: "-150vh" },
  visible: {
    visibility: "visible",
    y: 0,
    transition: { duration: 0.5 },
  },
};

const DesktopSubNav = () => {
  const navRef = useRef(null);

  const { navVisible, setNavVisible } = useContext(AppContext);
  const {
    mainNavItems,
    mainNavSubItems,
    setActiveKey,
    setMainNavItems,
    setActiveMainNavItemKey,
    setMainNavSubItems,
  } = useContext(HeaderContext);

  const onMouseLeave = () => {
    setActiveKey("");
    setMainNavItems([]);
    setActiveMainNavItemKey("");
    setMainNavSubItems([]);
    setNavVisible(false);
  };

  return (
    <motion.div
      ref={navRef}
      className={`desktop-nav__items-wrapper ${
        !mainNavSubItems?.length ? "horizontal" : ""
      }`}
      variants={navVariants}
      initial="hidden"
      animate={navVisible && mainNavItems?.length ? "visible" : "hidden"}
      onMouseLeave={onMouseLeave}
    >
      {/* Left side of the navigation */}
      <div className="desktop-nav__left">
        {mainNavItems &&
          mainNavItems.map(({ key, path, label, description, subItems }) => (
            <MainNavItem
              key={key}
              keyProp={key}
              path={path}
              label={label}
              description={description}
              subItems={subItems}
              animate={navVisible && mainNavItems?.length}
            />
          ))}
      </div>

      {/* Right side of the navigation */}
      <div className="desktop-nav__right">
        {mainNavSubItems?.map(({ key, path, label, description }) => (
          <MainNavSubItem
            key={key}
            keyProp={key}
            path={path}
            label={label}
            description={description}
            animate={navVisible && mainNavItems?.length}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default DesktopSubNav;
